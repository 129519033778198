export const environment = {
  production: true,
  url: 'uat.integrity.gov',
  name: 'UAT',
  https: true,
  showRulesVersion: true,
  integrityProduction: false,
  servicePath: {
    APP_DATA_SERVICE_PATH: '/SecureProxy/ads/',
    DSS_SERVICE_PATH: '/SecureProxy/dss/',
    FORMS_DATA_PERSISTENCE_SERVICE_PATH: '/SecureProxy/fpd/',
    FORMS_TEMPLATE_PERSISTENCE_SERVICE_PATH: '/SecureProxy/fpt/',
    GRID_DATA_PERSISTENCE_SERVICE_PATH: '/SecureProxy/gpd/',
    GRID_TEMPLATE_PERSISTENCE_SERVICE_PATH: '/SecureProxy/gpt/',
    GROUPS_SERVICE_PATH: '/SecureProxy/groups/',
    NAVIGATION_SERVICE_PATH: '/SecureProxy/nav/',
    PERSISTENCE_SERVICE_PATH: '/SecureProxy/persistence/',
    PREFERENCES_SERVICE_PATH: '/SecureProxy/prefs/',
    VALIDATION_SERVICE_PATH: '/SecureProxy/validation/',
    VALUES_SERVICE_PATH: '/SecureProxy/values/',
    INTEGRITY_SERVICE_PATH: '/SecureProxy/is/',
    INTEGRITY_SERVICE_NODE_PATH: '/SecureProxy/isn/',
    SESSION_SERVICE_PATH: '/SessionService/',
  },
};
